<template lang="">
    <div>
        <a-button class="change-password" @click="actionModal(true)">
            {{
                userInfo.is_set_new_password ? $t('profile_page.set_new_password') : $t('profile_page.change_password')
            }}
        </a-button>
        <a-modal
            :title="
                userInfo.is_set_new_password ? $t('profile_page.set_new_password') : $t('profile_page.change_password')
            "
            :visible="visible"
            :footer="null"
            :maskClosable="false"
            @cancel="actionModal(false)"
        >
            <a-form :form="form" class="changePassoword__form">
                <a-form-item v-if="!userInfo.is_set_new_password">
                    <a-input-password
                        type="password"
                        v-decorator="[
                            'old_password',
                            {
                                rules: [
                                    { required: true, message: $t('form.current_password_required') },
                                    { validator: checkPassword },
                                ],
                            },
                        ]"
                        :placeholder="$t('form.current_password')"
                    />
                </a-form-item>
                <a-form-item>
                    <a-input-password
                        type="password"
                        v-decorator="[
                            'new_password',
                            {
                                rules: [
                                    { required: true, message: $t('form.new_password_required') },
                                    { validator: checkPassword },
                                ],
                            },
                        ]"
                        :placeholder="$t('form.new_password')"
                    />
                </a-form-item>
                <a-form-item>
                    <a-input-password
                        type="password"
                        v-decorator="[
                            'confirm_password',
                            {
                                rules: [
                                    { required: true, message: $t('form.confirm_password_required') },
                                    { validator: compareToFirstPassword },
                                ],
                            },
                        ]"
                        :placeholder="$t('form.confirm_password')"
                        @blur="handleConfirmBlur"
                    />
                </a-form-item>
                <button @click="onUpdate">{{ $t('form.update') }}</button>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import md from 'md5';
import * as UserAPI from '../../../api/userAPI';
import { mixinToasts, mixinLoadings } from '../../../mixins';
import { checkIsMaster } from '../../../utils';

export default {
    mixins: [mixinToasts, mixinLoadings],
    data() {
        return {
            userInfo: this.$store.getters.getUserInfo,
            form: this.$form.createForm(this, { name: 'change_password' }),
            confirmDirty: false,
            visible: false,
        };
    },
    watch: {
        '$store.state.auth.userInfo': function () {
            this.userInfo = this.$store.getters.getUserInfo;
        },
    },
    methods: {
        actionModal(value) {
            this.visible = value;
            if (!value) this.form.resetFields();
        },

        handleConfirmBlur(e) {
            const value = e.target.value;
            this.confirmDirty = this.confirmDirty || !!value;
        },

        checkPassword(rule, value, callback) {
            const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
            if (value) {
                if (!pattern.test(value)) {
                    callback(this.$t('form.incorrect_password_format'));
                } else if (this.confirmDirty) {
                    this.form.validateFields(['confirm_password'], { force: true });
                }
                callback();
            } else {
                callback();
            }
        },

        compareToFirstPassword(rule, value, callback) {
            const form = this.form;
            if (value && value !== form.getFieldValue('new_password')) {
                callback(this.$t('form.incorrect_confirm_password'));
            } else {
                callback();
            }
        },

        onUpdate() {
            this.form.validateFields(async (error, values) => {
                if (!error) {
                    try {
                        this.showLoading();
                        if (this.userInfo.is_set_new_password) {
                            await UserAPI.setPassword({
                                new_password: md(values.new_password.trim()),
                            });
                            this.showToast('success', this.$t('alert.UPDATE_SUCCESS'));
                        } else {
                            if (checkIsMaster(this.userInfo)) {
                                await UserAPI.changePasswordMaster({
                                    old_password: md(values.old_password.trim()),
                                    new_password: md(values.new_password.trim()),
                                });
                            } else {
                                await UserAPI.changePassword({
                                    old_password: md(values.old_password.trim()),
                                    new_password: md(values.new_password.trim()),
                                });
                            }
                            this.showToast('success', this.$t('reset_password.text_success'));
                        }

                        this.$store.dispatch('updateInfo', { ...this.userInfo, is_set_new_password: false });
                        this.actionModal(false);
                    } catch (error) {
                        this.showError(error);
                    } finally {
                        this.hideLoading();
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.changePassoword__form {
    & .ant-modal-title {
        font-size: 16px;
        font-weight: 700 !important;
    }
    & .ant-input:hover,
    & .ant-input:focus {
        border-color: #000 !important;
    }
    & button {
        display: block;
        width: 100%;
        max-width: 160px;
        height: 40px;
        padding: 0;
        margin: 0 auto;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        border-radius: 6px;
        box-shadow: unset;
    }
    & button:hover {
        background-color: #000;
        color: #fff;
    }
}
</style>
