<template lang="" style="display: flex; alignitem: center">
    <div class="profile-page">
        <h2>{{ $t('profile_page.title') }}</h2>
        <div class="profile__content">
            <div class="profile__info">
                <div class="profile__avatar" @click="handleClickAvatar">
                    <img
                        :src="imageState ? imageState.imgBase64 : userInfo.avatar"
                        alt="avatar"
                        v-if="imageState || linkImgRule(userInfo.avatar)"
                    />
                    <img src="../../assets/images/no_image.jpg" alt="avatar" v-else />
                    <div class="update-avatar">
                        <v-icon class="fa-solid fa-image"></v-icon>
                        <input
                            type="file"
                            ref="inputImage"
                            accept="image/jpeg, image/jpg, image/png, image/heic, image/gif"
                            @change="handleUploadAvatar"
                            hidden
                        />
                    </div>
                </div>
                <p>{{ userInfo.full_name }}</p>
            </div>
            <a-form class="profile__form" :form="form">
                <a-form-item :label="$t('form.email')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-input
                        type="text"
                        v-decorator="[
                            'email',
                            {
                                initialValue: userInfo.email,
                            },
                        ]"
                        disabled
                    />
                </a-form-item>
                <a-form-item :label="$t('form.full_name')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-input
                        type="text"
                        :max-length="255"
                        v-decorator="[
                            'full_name',
                            {
                                initialValue: userInfo.full_name,
                                rules: [{ required: true, message: $t('form.fullname_required'), whitespace: true }],
                            },
                        ]"
                    />
                </a-form-item>
                <a-form-item :label="$t('form.gender')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                    <a-radio-group
                        v-decorator="[
                            'gender',
                            {
                                initialValue: userInfo.gender,
                            },
                        ]"
                    >
                        <a-radio value="MALE"> {{ $t('form.male') }} </a-radio>
                        <a-radio value="FEMALE"> {{ $t('form.female') }} </a-radio>
                        <a-radio value="OTHER"> {{ $t('form.other') }} </a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20, offset: 4 }">
                    <div class="profile__btns">
                        <ChangePassword />
                        <a-button class="update" @click="onUpdate"> {{ $t('form.update') }} </a-button>
                    </div>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>

<script>
import * as UserAPI from '../../api/userAPI';
import * as ImageAPI from '../../api/imageAPI';
import ChangePassword from '../../components/Web/ChangePassword';
import { mixinToasts, mixinLoadings, mixinRules } from '../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings, mixinRules],
    components: {
        ChangePassword,
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'update_profile' }),
            userInfo: this.$store.getters.getUserInfo,
            imageState: null,
        };
    },
    watch: {
        '$store.state.auth.userInfo': function () {
            this.userInfo = this.$store.getters.getUserInfo;
        },
    },
    methods: {
        onUpdate() {
            this.form.validateFields(async (error, values) => {
                if (!error) {
                    this.showLoading();
                    try {
                        let payload = {
                            ...values,
                            full_name: values.full_name.trim(),
                        };

                        if (this.imageState) {
                            // Call API upload avatar
                            const body = new FormData();
                            body.append('image', this.imageState.file);
                            const res = await ImageAPI.upload(body);
                            payload.avatar = res?.results?.object?.url;
                        }

                        // Call API update user
                        const res = await UserAPI.update(this.userInfo?.id, payload);
                        const data = res?.results?.object;
                        this.$store.dispatch('updateInfo', data);
                        this.userInfo = data;
                        this.form.setFieldsValue({ email: data.email, full_name: data.full_name, gender: data.gender });
                        setTimeout(() => {
                            this.hideLoading();
                            this.showToast('success', this.$t('profile_page.successfully_updated'));
                        }, 500);
                    } catch (error) {
                        this.hideLoading();
                        this.showError(error);
                    }
                }
            });
        },

        handleClickAvatar() {
            this.$refs.inputImage && this.$refs.inputImage.click();
        },

        handleUploadAvatar(e) {
            const file = e.target.files[0];

            if (!this.isImageFile(file)) return false;

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.imageState = {
                    file,
                    imgBase64: reader.result,
                };
            });
            reader.readAsDataURL(file);
        },
    },
};
</script>

<style lang="scss">
.profile-page {
    width: 100%;
    max-width: 1170px;
    margin: 80px auto;
    padding: 0 15px;

    & h2 {
        font-size: 36px;
        text-align: center;
    }
}

.profile__info {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;

    & p {
        max-width: 100%;
        margin: 20px auto;
        line-height: 1.5;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
}

.profile__avatar {
    position: relative;
    width: fit-content;
    margin: 0 auto;

    & img {
        width: 200px;
        height: 200px;
        border-radius: 100%;
        color: rgb(204, 204, 204);
        box-shadow: rgb(44 80 172 / 25%) 0px 4px 15px;
        border: 4px solid rgb(255, 255, 255);
        object-fit: cover;
    }

    & .update-avatar {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transition: all 0.4s ease-in-out;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;

        & i {
            color: #fff;
        }
    }

    &:hover .update-avatar {
        opacity: 1;
        visibility: visible;
    }
}

.profile__content {
    display: flex;
    width: 100%;
    margin: 40px auto 0px;
    padding: 55px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 6px 15px rgb(218 220 237 / 50%);
}

.profile__form {
    width: 100%;

    & h3 {
        margin-bottom: 30px;
        font-size: 22px;
    }

    &.ant-form label {
        font-size: 16px !important;
    }

    & input {
        width: 100%;

        &:hover,
        &:focus {
            border-color: #000;
        }
    }
}

.profile__btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    & > div {
        width: 100%;
        max-width: 200px;
        line-height: 40px;
    }

    & button.ant-btn {
        width: 100%;
        max-width: 200px;
        height: 40px;
        padding: 0;
        font-size: 16px;
        box-shadow: unset;
    }

    & .change-password {
        background-color: #f1f4f8;
        color: #333333;
        border-color: #d5dde7;
    }

    & .update {
        background-color: #000;
        color: #fff;
        border-color: #000;
    }
}

@media screen and (max-width: 1024px) {
    .profile__content {
        flex-direction: column;
        padding: 20px;
    }
}

@media screen and (max-width: 575px) {
    .profile__form {
        & .ant-row {
            margin-bottom: 30px;
        }

        & .ant-form-item-label {
            line-height: 1;
            padding-bottom: 10px;
        }

        & .ant-row:last-child .ant-col {
            margin-left: 0 !important;
        }
    }

    .profile__btns {
        & > div,
        & button.ant-btn {
            max-width: 100%;
        }
    }
}
</style>
