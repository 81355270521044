var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-button',{staticClass:"change-password",on:{"click":function($event){return _vm.actionModal(true)}}},[_vm._v(" "+_vm._s(_vm.userInfo.is_set_new_password ? _vm.$t('profile_page.set_new_password') : _vm.$t('profile_page.change_password'))+" ")]),_c('a-modal',{attrs:{"title":_vm.userInfo.is_set_new_password ? _vm.$t('profile_page.set_new_password') : _vm.$t('profile_page.change_password'),"visible":_vm.visible,"footer":null,"maskClosable":false},on:{"cancel":function($event){return _vm.actionModal(false)}}},[_c('a-form',{staticClass:"changePassoword__form",attrs:{"form":_vm.form}},[(!_vm.userInfo.is_set_new_password)?_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'old_password',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.current_password_required') },
                                { validator: _vm.checkPassword } ],
                        } ]),expression:"[\n                        'old_password',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.current_password_required') },\n                                { validator: checkPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.current_password')}})],1):_vm._e(),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'new_password',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.new_password_required') },
                                { validator: _vm.checkPassword } ],
                        } ]),expression:"[\n                        'new_password',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.new_password_required') },\n                                { validator: checkPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.new_password')}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'confirm_password',
                        {
                            rules: [
                                { required: true, message: _vm.$t('form.confirm_password_required') },
                                { validator: _vm.compareToFirstPassword } ],
                        } ]),expression:"[\n                        'confirm_password',\n                        {\n                            rules: [\n                                { required: true, message: $t('form.confirm_password_required') },\n                                { validator: compareToFirstPassword },\n                            ],\n                        },\n                    ]"}],attrs:{"type":"password","placeholder":_vm.$t('form.confirm_password')},on:{"blur":_vm.handleConfirmBlur}})],1),_c('button',{on:{"click":_vm.onUpdate}},[_vm._v(_vm._s(_vm.$t('form.update')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }