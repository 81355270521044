var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-page"},[_c('h2',[_vm._v(_vm._s(_vm.$t('profile_page.title')))]),_c('div',{staticClass:"profile__content"},[_c('div',{staticClass:"profile__info"},[_c('div',{staticClass:"profile__avatar",on:{"click":_vm.handleClickAvatar}},[(_vm.imageState || _vm.linkImgRule(_vm.userInfo.avatar))?_c('img',{attrs:{"src":_vm.imageState ? _vm.imageState.imgBase64 : _vm.userInfo.avatar,"alt":"avatar"}}):_c('img',{attrs:{"src":require("../../assets/images/no_image.jpg"),"alt":"avatar"}}),_c('div',{staticClass:"update-avatar"},[_c('v-icon',{staticClass:"fa-solid fa-image"}),_c('input',{ref:"inputImage",attrs:{"type":"file","accept":"image/jpeg, image/jpg, image/png, image/heic, image/gif","hidden":""},on:{"change":_vm.handleUploadAvatar}})],1)]),_c('p',[_vm._v(_vm._s(_vm.userInfo.full_name))])]),_c('a-form',{staticClass:"profile__form",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":_vm.$t('form.email'),"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'email',
                        {
                            initialValue: _vm.userInfo.email,
                        } ]),expression:"[\n                        'email',\n                        {\n                            initialValue: userInfo.email,\n                        },\n                    ]"}],attrs:{"type":"text","disabled":""}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.full_name'),"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'full_name',
                        {
                            initialValue: _vm.userInfo.full_name,
                            rules: [{ required: true, message: _vm.$t('form.fullname_required'), whitespace: true }],
                        } ]),expression:"[\n                        'full_name',\n                        {\n                            initialValue: userInfo.full_name,\n                            rules: [{ required: true, message: $t('form.fullname_required'), whitespace: true }],\n                        },\n                    ]"}],attrs:{"type":"text","max-length":255}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('form.gender'),"label-col":{ span: 4 },"wrapper-col":{ span: 20 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'gender',
                        {
                            initialValue: _vm.userInfo.gender,
                        } ]),expression:"[\n                        'gender',\n                        {\n                            initialValue: userInfo.gender,\n                        },\n                    ]"}]},[_c('a-radio',{attrs:{"value":"MALE"}},[_vm._v(" "+_vm._s(_vm.$t('form.male'))+" ")]),_c('a-radio',{attrs:{"value":"FEMALE"}},[_vm._v(" "+_vm._s(_vm.$t('form.female'))+" ")]),_c('a-radio',{attrs:{"value":"OTHER"}},[_vm._v(" "+_vm._s(_vm.$t('form.other'))+" ")])],1)],1),_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 20, offset: 4 }}},[_c('div',{staticClass:"profile__btns"},[_c('ChangePassword'),_c('a-button',{staticClass:"update",on:{"click":_vm.onUpdate}},[_vm._v(" "+_vm._s(_vm.$t('form.update'))+" ")])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }